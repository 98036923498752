body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "prompt";
  font-style: lighter;
  font-weight: 200;
  src: local("prompt Light"), local("prompt-Light"),
    url("./assets/fonts/Prompt/Prompt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "prompt";
  font-style: normal;
  font-weight: 400;
  src: local("prompt Medium"), local("prompt-Medium"),
    url("./assets/fonts/Prompt/Prompt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "prompt";
  font-style: bold;
  font-weight: 800;
  src: local("prompt Bold"), local("prompt Bold"),
    url("./assets/fonts/Prompt/Prompt-Bold.ttf") format("truetype");
}

div,
button,
label,
input,
textarea,
select,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: "prompt" !important;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
:focus {
  outline: none;
}

.otp-modal {

  .modal-container{
    display: flex;
    flex-direction: column;
  }


  p {
    font-size: 20px;
    color: #424242;
    margin: 20px auto;
    width: 80%;
  }

  .modal-content {
    margin-top: 15vh;
    width: 100%;
    max-width: 650px;
    height: 530px;
  }


  .logo {
    width: 136px;
    height: 54px;
    margin: 0 auto;
    margin-top: 40px;
    display: block;
  }

  .submit-bt {
    width: 291px;
    height: 60px;
    margin: 0 auto;
    display: block;
    font-size: 20px;
    margin: 22px 0;
  }

  .try-again-text {
    border:none;
    font-size: 20px;
    margin: 20px auto;
    text-decoration: underline;
    text-align: center;
  }

  .otp-input {
    font-size: 40px;
    margin: 20px auto;

    .otp-input-hidden{
      position: absolute;
      opacity: 0;
      height:10px;
    }

   .input-element{
     display: flex;
      .input-character {
        width: 40px;
        height: 60px;
        border: none;
        margin: 5px;
        color: #0056bb;
        border-radius: 2px;
        border-bottom: 5px solid #2f80ed;
        text-align: center;
      }
    }

    .wrong-otp {
      color: #ff0000;
      border-bottom: 5px solid #ff0000;
    }
  }

  .wrong-otp-text{
    text-align: center;
    color: #FF0000;
    font-size: 14px;
  }
  .error-section{
    max-width:500px;
    margin:0 auto;

    .error-massage{
      text-align: center;
      font-size: 25px;
      padding: 30px 0;
      color: #ff0000;
    }
    
    .a-tag-button-style{
      display: block;
      margin:auto;
      color: #fff;
      padding: 12px;
      width: 239px;
      height: 48px;
      border-radius: 12px;
      font-size: 20px;
      text-decoration: none;
    }
  }

}

.privilege-apply {
  max-width: 500px;
  padding: 0 15px;
  margin: auto;

  input::placeholder {
    color: #c6d0eb;
  }

  a {
    color: #151522;
    text-decoration: underline;
    padding: 5px;
  }

  .welcome {
    font-size: 28px;
    line-height: 42px;
    color: #0056bb;
    padding-bottom: 10px;
  }

  .company-logo {
    display: block;
    margin: 29px auto;
    padding-top: 55px;
    max-height: 150px;
  }

  .apply-form-description {
    max-width: 500px;
    margin: 25px 0;
    text-align: left;
    font-size: 14px;
    color: #151522;
  }

  .apply-form {
    width:100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .apply-form-input {
    max-width: 500px;
    height: 45px;
    color: #0056bb;
    border: 1px solid #c6d0eb;
    border-radius: 12px;
    padding: 18px;
    font-size: 18px;
  }

  .apply-bt-group {
    display: flex;
    justify-content: space-around;
  }

  .apply-form-bt {
    width: 100%;
    height: 48px;
    max-width: 215px;
    margin: 0 10px;
    border-radius: 12px;
    font-size: 20px;
  }

  .primary {
    color: #fff;
    background: #0056bb;
  }

  .decline {
    color: #0056bb;
    background: #fff;
    border: 1px solid #0056bb;
    padding: 7px;
    text-decoration: none;
    text-align: center;
  }

  .apply-form-term {
    padding: 0 5px;
  }

  .apply-form-auto-fill {
    color: #727fa5;
    padding: 0 0 20px 0;
    font-size: 18px;
  }

  .it-not-you-link {
    font-size: 12px;
    padding-left: 5px;
    color: #0056bb;
    text-decoration: underline;
  }

  .consent {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .input-name {
    font-size: 12px;
    color: #0056bb;
    margin-bottom: 10px;
  }
}

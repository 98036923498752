.terms-and-condition{

  h1{
    font-size: 25px;
  }
  h2{
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }

  max-width: 700px;
  min-height: 736px;
  margin: auto;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
  padding: 20px 4%;
}

.navbar {
  height: 6vh;
  padding: 0;
  background: #00c4b6;
}

.navbar-logo {
  margin: auto;
  display: block;
}

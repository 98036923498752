.finale{
  height: 95vh;
  background: #F4FCFB;
  text-align: center;

  h1{
    color:#0056BB;
    font-size: 35px;
  }

  p{
    max-width: 500px;
    margin:30px auto;
    font-size: 24px;
    color:#424242;
  }

  .welcome-logo{
    display: block;
    margin: auto;
    padding: 80px 0 40px;
  }
  .submit-bt{
    width: 100%;
    max-width: 360px;
    height: 55px;
    font-size: 25px;
  }
}
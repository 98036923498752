.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.btn-primary {
  color: #fff;
  border-color: #0056bb;
  background: #0056bb;
  box-shadow: none;
}

.error-message{
  color:#ff0000;
}
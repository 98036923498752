.privacy-policy {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }

  max-width: 700px;
  min-height: 85vh;
  margin: auto;
  margin-top: 15px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
  padding: 10px;

  .privacy-policy-iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }
}
